/* @jsx jsx */
import React from 'react';
import { jsx } from '../../provider';

class LogoText extends React.Component {
  render() {
    const {
      sx,
      iconText = 'Change iconText',
      viewBox = '0 0 500 500',
      width = '500',
    } = this.props;
    return (
      <svg
        sx={{
          '& #curve': {
            fill: 'transparent',
          },
          ...sx,
        }}
        viewBox={viewBox}
      >
        <path
          id="curve"
          d="m 102.18221,72.383994 c 11.6118,-2.032192 21.35916,8.073189 22.38977,19.085657 1.46162,15.618039 -12.10419,28.231579 -27.102887,28.974989 -19.604511,0.9717 -35.142839,-16.1394 -35.560215,-35.120116 -0.518627,-23.585159 20.176205,-42.074395 43.137352,-42.14544 27.56368,-0.08529 49.01791,24.213824 48.73066,51.154579 -0.3363,31.541327 -28.25195,55.969077 -59.171809,55.315887 C 59.086509,148.89922 31.679663,117.35913 32.703967,82.460512 33.812505,44.692012 66.193635,14.814508 103.4519,13.945866"
        />
        <text id="mainText" width={width}>
          <textPath href={'#curve'} xlinkHref={'#curve'}>
            {iconText}
          </textPath>
        </text>
      </svg>
    );
  }
}

export default LogoText;
