import React from 'react';
import {
  GatsbyLink,
  GatsbyPageLink,
  GatsbyLinkButton,
  PreDebug,
  Button,
  IconButton,
} from '@talves/gatsby-theme-components-tui';
import { Box, useThemeUI } from '../provider';
import { Layout } from '../components/Layouts';
import BurgerIcon from '../components/icons/BurgerIcon';
import DartHeading from '../components/icons/DartHeading';
import LogoIcon from '../components/icons/LogoIcon';
import LogoText from '../components/icons/LogoText';
import NameLogo from '../components/icons/NameLogo';

export default props => {
  const { theme } = useThemeUI();

  return (
    <Layout header="Just a test page for development">
      <Box sx={{ mb: '0.5rem' }}>
        <GatsbyLinkButton to="/">Gatsby Link Button</GatsbyLinkButton>
      </Box>
      <Box>
        <GatsbyPageLink sx={{ fontSize: ['1rem', '1.2rem'] }} to="/">
          Gatsby Link - Home
        </GatsbyPageLink>
      </Box>
      <Box>
        <GatsbyLink sx={{ fontSize: [2, 3, 4] }} to="/core">
          Gatsby Link - Core
        </GatsbyLink>
      </Box>
      <Box>
        <BurgerIcon sx={{ height: 40 }} />
      </Box>
      <hr />
      <Box sx={{ mt: '5px', mb: '5px' }}>
        <Button>Button</Button>
      </Box>
      <Box sx={{ mt: '5px', mb: '5px' }}>
        <Button>Sm.</Button>
      </Box>
      <Box sx={{ mt: '5px', mb: '5px' }}>
        <Button disabled>Disabled Button</Button>
      </Box>
      <Box sx={{ mt: '5px', mb: '5px' }}>
        <IconButton hiddenText altText="Home Icon Only Button" icon="home" />
      </Box>
      <Box sx={{ mt: '5px', mb: '5px' }}>
        <IconButton altText="visibility Button" icon="visibility" />
      </Box>
      <Box sx={{ mt: '5px', mb: '5px' }}>
        <IconButton
          sx={{ fontSize: '20px' }}
          altText="Home Button"
          icon="home"
        />
      </Box>
      <hr />

      <Box>
        <DartHeading
          iconText="Test Header"
          secondaryColor={theme.colors.secondary}
          primaryColor={theme.colors.primary}
          sx={{
            height: 240,
            '& #mainText': {
              'alignment-baseline': 'middle',
              // fontFamily: theme.typography.fontFamilySecond,
              fill: theme.colors.muted,
              fontSize: 64,
              fontWeight: 700,
              'text-anchor': 'middle',
            },
          }}
        />
      </Box>
      <Box
        sx={{ border: 'solid 2px', width: 54, height: 54, borderRadius: '50%' }}
      >
        <LogoIcon sx={{ width: 50 }} />
      </Box>
      <Box sx={{ bg: theme.colors.secondary }}>
        <NameLogo sx={{ height: [30, 40, 50] }} />
      </Box>
      <Box
        sx={{
          border: 'solid 2px',
          width: 200,
          height: 200,
          borderRadius: '50%',
          fontSize: '1rem',
        }}
      >
        <LogoText
          iconText="This is a really long sentence to test it out, and I am hoping it will show up well. 😎"
          width="200"
          viewBox="0 0 200 200"
          sx={{ borderRadius: '50%', backgroundColor: theme.colors.background }}
        />
      </Box>
      {props.children}
      <PreDebug />
    </Layout>
  );
};
//
